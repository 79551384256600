import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      alert: '',
      alertVariant: 'info',
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        form.reset();
        this.setState({
          alert: 'Thanks for your enquiry, we will get back to you soon',
          disabled: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          disabled: false,
          alert:
            'There is a problem, your message has not been sent, please try contacting us via email',
          alertVariant: 'danger',
        });
      });
  };

  render() {
    return (
      <>
        <Form
          name="contact"
          methid="post"
          action="/contact"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {this.state.alert && (
            <Alert
              variant={this.state.alertVariant}
              className="position-fixed shadow"
              onClose={() => this.setState({ alert: '' })}
              dismissible
              style={{
                minWidth: '300px',
                top: '100px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {this.state.alert}
            </Alert>
          )}
          <div className="d-flex justify-content-between">
            <Form.Group className="w-100 pr-4">
              <Form.Label>Firstname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Edward"
                name="firstname"
                className="shadow-sm"
                required
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="w-100 pl-4">
              <Form.Label>Lastname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Smith"
                name="lastname"
                className="shadow-sm"
                required
                onChange={this.handleChange}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="example@email.com"
              name="emailAddress"
              className="shadow-sm"
              required
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="I would like to ..."
              name="message"
              className="shadow-sm"
              rows="4"
              required
              onChange={this.handleChange}
            />
          </Form.Group>
          <Button type="submit" size="lg" disabled={this.state.disabled} className="shadow">
            Send
          </Button>
        </Form>
      </>
    );
  }
}

export default ContactForm;
