import React from 'react';
import { MapPin, Smartphone, Mail } from 'react-feather';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader';
import ContactForm from '../components/ContactForm';
import Content from '../components/Content';
import Layout from '../components/Layout';

const StyledMain = styled.main`
  .list-group-item {
    a {
      color: ${(props) => props.theme.textColor};
    }
    background-color: ${(props) => props.theme.backgroundColor};
  }
`;

// Export Template for use in CMS preview
export const ContactPageTemplate = ({ body, title, subtitle, address, phone, email }) => (
  <StyledMain className="Contact">
    <PageHeader title={title} subtitle={subtitle} />
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <ContactForm />
        </Col>
        <Col xs={12} md={6}>
          <ListGroup variant="flush">
            <ListGroupItem className="pt-0 px-0">
              <Content source={body} />
            </ListGroupItem>
            {address && (
              <ListGroupItem className="px-0">
                <a
                  href={`https://www.google.com.au/maps/search/${encodeURI(address)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              </ListGroupItem>
            )}
            {phone && (
              <ListGroupItem className="px-0">
                <a href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              </ListGroupItem>
            )}
            {email && (
              <ListGroupItem className="px-0">
                <a href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              </ListGroupItem>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  </StyledMain>
);

const ContactPage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false} title={page.frontmatter.title || false}>
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
);

export default ContactPage;

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        address
        phone
        email
        locations {
          mapLink
          lat
          lng
        }
      }
    }
  }
`;
