import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import Content from './Content';

const PageHeader = ({ title, subtitle }) => {
  const StyledPageHeader = styled.header`
    background-image: linear-gradient(
      135deg,
      ${(props) => props.theme.primaryColor},
      ${(props) => props.theme.primaryHoverColor}
    );

    .angle-bottom-placeholder {
      border-bottom-color: ${(props) => props.theme.backgroundColor} !important;
    }
  `;

  return (
    <StyledPageHeader className="jumbotron position-relative text-white angle-bottom rounded-0 px-0">
      <div className="angle-bottom-placeholder" />
      <Container className="pt-5">
        <h1 className="display-4 font-weight-normal mb-0">{title}</h1>
        {subtitle && <Content src={subtitle} />}
      </Container>
    </StyledPageHeader>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PageHeader;
